<template>
	<RegisterComponent />
</template>

<script>
import RegisterComponent from '@/components/Register.vue';

export default {
	name: 'Register',
	components: { RegisterComponent },
};
</script>
