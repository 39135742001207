<template>
	<v-content>
		<v-container fluid class="mt-2">
			<v-row justify="center">
				<v-col cols="12" sm="8" md="4" v-if="!successRegister">
					<v-card class="elevation-0">
						<div class="title title-container mb-10">
							<h1>Découvrir OrthographIQ</h1>
						</div>
						<v-card-text class="text-center">
							<v-alert v-if="error" type="error" data-testid="errorMessage">{{ errorMessage }}</v-alert>
							<v-btn
								v-if="!isOniOSApp"
								depressed
								dark
								block
								large
								class="mx-auto mb-4 pr-0 pl-0 pt-0 pb-0"
								color="#db4a39"
								data-testid="googleRegisterButton"
							>
								<GoogleLogin
									:params="params"
									:onSuccess="OnGoogleAuthSuccess"
									style="width: 100%; height: 40px"
									class="d-flex align-center justify-center googlebtn"
								>
									<v-icon size="20px" class="mr-3">mdi-google</v-icon>
									<span class="font-weight-black fs15 googlebtntext">Continuer avec Google</span></GoogleLogin
								>
							</v-btn>

							<!-- <v-btn
								depressed
								block
								dark
								large
								@click="registerWithFacebook()"
								class="mx-auto"
								color="#3b5998"
								data-testid="facebookRegisterButton"
							>
								<v-icon size="20px" class="mr-3">mdi-facebook</v-icon>
								<span class="font-weight-black fs15">Continuer avec Facebook</span>
							</v-btn> -->

							<p v-if="!isOniOSApp" class="mt-7 mb-7 text-center">Ou</p>

							<v-form v-model="valid">
								<v-text-field
									v-model="email"
									:rules="emailRules"
									label="Adresse email *"
									@click="error = false"
									required
									outlined
									color="black"
									data-testid="email"
								></v-text-field>
								<v-text-field
									v-model="firstName"
									:rules="firstNameRules"
									label="Prénom *"
									@click="error = false"
									required
									outlined
									color="black"
									data-testid="firstName"
								></v-text-field>
								<v-text-field
									v-model="lastName"
									:rules="lastNameRules"
									label="Nom *"
									@click="error = false"
									required
									outlined
									color="black"
									data-testid="lastName"
								></v-text-field>
								<v-checkbox v-model="cguCheckbox" color="black" class="mt-0 pt-0">
									<template v-slot:label>
										<div class="checkbox-label">
											J’accepte les
											<a
												target="_blank"
												href="https://www.orthographiq.com/cgu?utm_source=signup"
												@click.stop
												v-on="on"
											>
												CGU
											</a>
											et la
											<a
												target="_blank"
												href="https://www.orthographiq.com/donnees-personnelles?utm_source=signup"
												@click.stop
												v-on="on"
											>
												politique des ‍données personnelles
											</a>
										</div>
									</template>
								</v-checkbox>
							</v-form>
						</v-card-text>
						<div class="button-validate">
							<v-btn
								:disabled="!valid || !cguCheckbox"
								:loading="loading"
								depressed
								large
								color="#15c39a"
								class="hvr-grow"
								data-testid="submit"
								@click="validate()"
							>
								<span class="white--text">C'est parti</span>
							</v-btn>
						</div>
						<div class="alreadyHaveAccount">
							<router-link :to="{ name: 'LoginSSO' }"><span class="text-2">SSO</span></router-link>
							<br />
							<router-link :to="{ name: 'Login' }"><span class="text-2">Déjà inscrit ? Connecte-toi</span></router-link>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" sm="8" md="8" v-else>
					<RegisterSuccess :email="email" />
				</v-col>
			</v-row>
		</v-container>
	</v-content>
</template>

<script>
import RegisterSuccess from './RegisterSuccess';
import { initFacebook } from '../assets/js/init-facebook-login';
import { GoogleClientId } from '../config/social-connect';
import axios from 'axios';
import GoogleLogin from 'vue-google-login';

import { detectIosApp } from '../utils/helper';

export default {
	name: 'RegisterComponent',
	components: {
		RegisterSuccess,
		GoogleLogin,
	},
	data: () => ({
		valid: false,
		showPassword: false,
		displayForgotten: false,
		email: '',
		emailRules: [
			(v) => !!v || "L'adresse email est un champ obligatoire",
			(v) => /.+@.+/.test(v) || "L'adresse email n'est pas valide",
		],
		firstName: '',
		firstNameRules: [(v) => !!v || 'Saisis ton prénom'],
		lastName: '',
		lastNameRules: [(v) => !!v || 'Saisis ton nom'],
		error: false,
		errorMessage: '',
		successRegister: false,
		loading: false,
		cguCheckbox: false,
		params: {
			client_id: GoogleClientId,
		},
		isOniOSApp: detectIosApp(),
	}),
	created() {
		initFacebook();
	},
	methods: {
		async validate() {
			try {
				this.loading = true;
				await this.$store.dispatch('accountManager/register', {
					email: this.email.toLowerCase(),
					firstName: this.firstName,
					lastName: this.lastName,
				});
				this.successRegister = true;
				if (process.env.VUE_APP_MIXPANEL == 'production') {
					this.$mixpanel.people.set({ $name: this.email, $email: this.email });
					this.$mixpanel.identify(this.email);
					this.$mixpanel.track('Register-Form');
				}
			} catch (error) {
				this.error = !this.error;
				if (error.response.status == 400) {
					this.errorMessage = error.response.data.message;
					this.loading = false;
				}
			}
		},
		async OnGoogleAuthSuccess(googleUser) {
			const idToken = googleUser.getAuthResponse().id_token;
			try {
				const res = await this.$http.post('/api/users/register/google', { idToken });
				/* Check response error */
				if (res.error) {
					this.error = true;
					this.errorMessage = res.message;
					return;
				}
				localStorage.setItem('token', res.data.token);
				this.successRegister = true;
				this.email = res.data.email;
				if (process.env.VUE_APP_MIXPANEL == 'production') {
					this.$mixpanel.people.set({ $name: res.data.email, $email: res.data.email });
					this.$mixpanel.identify(res.data.email);
					this.$mixpanel.track('Register-Google');
				}
				this.$router.push(`/onboarding/${res.data.userId}`);
			} catch (error) {
				this.error = !this.error;
				this.errorMessage = error.response.data.message;
			}
		},
		registerWithFacebook: async function () {
			var VueObject = this;
			await window.FB.login(
				async function (response) {
					if (response.authResponse) {
						try {
							const res = await axios.post('/api/users/register/facebook', {
								idToken: response.authResponse.accessToken,
							});
							/* Check response error */
							if (res.error) {
								this.error = true;
								this.errorMessage = res.message;
								return;
							}
							localStorage.setItem('token', res.data.token);
							VueObject.successRegister = true;
							VueObject.email = res.data.email;
							if (process.env.VUE_APP_MIXPANEL == 'production') {
								this.$mixpanel.people.set({ $name: res.data.email, $email: res.data.email });
								this.$mixpanel.identify(res.data.email);
								this.$mixpanel.track('Register-Facebook');
							}
							this.$router.push(`/onboarding/${res.data.userId}`);
						} catch (error) {
							VueObject.error = true;
							VueObject.errorMessage = error.response.data.message;
						}
					}
				},
				{ scope: 'email' }
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.googlebtntext {
	font-weight: 500;
	text-indent: 0.0892857143em;
	letter-spacing: 0.0892857143em;
	text-transform: uppercase;
}
.googlebtn:focus {
	outline: 0;
}
.title-container {
	text-align: center;
}
.button-validate {
	text-align: center;
	padding-bottom: 20px;
}
.alreadyHaveAccount {
	text-align: center;
	padding-bottom: 30px;
	cursor: pointer;
}
.text-2 {
	color: black;
	text-decoration: underline;
}
.text-2:hover {
	color: grey;
}
.checkbox-label {
	font-family: 'Lato';
	a {
		text-decoration: underline;
		color: black;
	}
}
</style>
