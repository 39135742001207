<template>
	<div class="d-flex align-center justify-center flex-column" style="min-height: 50vh" data-testid="registerSuccess">
		<div>
			<v-badge bordered color="red" content="1" overlap offset-x="20" offset-y="24">
				<v-icon size="80px" color="black">mdi-email-outline</v-icon>
			</v-badge>
		</div>
		<h1 class="mt-5 mb-4 title-signin">Félicitations pour ton inscription</h1>
		<h3 class="mb-6 text-center">
			Tu viens de recevoir un email à l'adresse suivante :
			<p class="blue--text">{{ email }}</p>
		</h3>
		<div class="mb-6 maindiv-btn-mail">
			<div class="submaindiv-btn-mail">
				<div class="leLuvu">
					<div class="container-btn-mail">
						<a href="https://bit.ly/3Ks7tmM" target="_blank" class="link-btn-mail">
							<div class="doteoW">
								<svg width="1em" height="1em" viewBox="0 0 20 20">
									<g fill-rule="nonzero" fill="none">
										<path fill="none" d="M0 0h20v20H0z"></path>
										<path
											d="M1.429 17.974h3.155v-7.662L.078 6.935v9.701c0 .74.61 1.338 1.35 1.338z"
											fill="#557EBF"
										></path>
										<path
											d="M15.416 17.974h3.155c.754 0 1.351-.61 1.351-1.35V6.921L15.416 10.3v7.675z"
											fill="#36A852"
										></path>
										<path
											d="M15.416 4.442v5.857l4.506-3.377V5.117c0-1.675-1.909-2.623-3.247-1.623l-1.26.948z"
											fill="#F9BC15"
										></path>
										<path fill="#EA4535" d="M4.584 10.312V4.455L10 8.519l5.416-4.064v5.857L10 14.364z"></path>
										<path
											d="M.078 5.117v1.805L4.584 10.3V4.442l-1.26-.948c-1.337-1-3.246-.04-3.246 1.623z"
											fill="#C52627"
										></path>
									</g>
								</svg>
								<span class="textopen-btn-mail">Ouvrir Gmail</span>
							</div>
						</a>
					</div>
					<div>
						<a href="https://bit.ly/3tK0PCz" target="_blank" class="link-btn-mail">
							<div class="doteoW">
								<svg width="1em" height="1em" viewBox="0 0 103.173 104.313">
									<path
										d="M64.567 22.116v20.405l7.13 4.49c.188.054.596.058.784 0l30.688-20.69c0-2.45-2.284-4.205-3.573-4.205h-35.03z"
										fill="#0072c6"
									></path>
									<path
										d="M64.567 50.133l6.507 4.47c.917.674 2.022 0 2.022 0-1.101.674 30.077-20.036 30.077-20.036V72.07c0 4.083-2.613 5.795-5.551 5.795h-33.06v-27.73z"
										fill="#0072c6"
									></path>
									<g fill="#0072c6">
										<path
											d="M30.873 40.726c-2.218 0-3.985 1.042-5.29 3.123-1.304 2.08-1.958 4.834-1.958 8.263 0 3.479.654 6.229 1.959 8.25 1.304 2.026 3.016 3.033 5.132 3.033 2.182 0 3.914-.983 5.191-2.95 1.278-1.967 1.92-4.698 1.92-8.188 0-3.64-.619-6.473-1.861-8.498-1.242-2.022-2.938-3.033-5.093-3.033z"
										></path>
										<path
											d="M0 11.754V91.58l60.727 12.733V0L0 11.754zm40.636 53.408c-2.566 3.377-5.912 5.07-10.041 5.07-4.024 0-7.3-1.638-9.834-4.91-2.531-3.275-3.8-7.537-3.8-12.795 0-5.552 1.285-10.042 3.859-13.47 2.574-3.428 5.982-5.144 10.225-5.144 4.008 0 7.252 1.638 9.724 4.92 2.476 3.284 3.715 7.61 3.715 12.98.003 5.521-1.282 9.972-3.848 13.349z"
										></path>
									</g>
								</svg>
								<span class="textopen-btn-mail">Ouvrir Outlook.com</span>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<p class="subText">PS : s'il n'apparaît pas dans ta boîte de réception d'ici 5min regarde dans tes SPAMS 👀</p>
	</div>
</template>

<script>
export default {
	name: 'RegisterSuccess',
	props: {
		email: String,
	},
};
</script>

<style scoped>
.subText {
	text-align: center;
	color: grey;
	text-decoration: underline;
}
.title-signin {
	text-align: center;
}
.maindiv-btn-mail {
	text-align: center !important;
}
.submaindiv-btn-mail {
	display: inline-block !important;
}
.link-btn-mail {
	padding: 10px !important;
	height: 20px;
	width: 240px;
	-webkit-box-align: center;
	align-items: center;
	background-color: transparent;
	border: 1px solid rgb(208, 213, 232);
	border-radius: 2px;
	color: rgb(1, 7, 44);
	display: flex;
	flex-direction: row;
	font-family: Inter, Tahoma, Geneva, sans-serif;
	font-feature-settings: 'ss01';
	font-weight: 600;
	-webkit-box-pack: center;
	justify-content: center;
	transition: all 0.15s ease 0s;
	font-size: 17px;
	height: 44px;
	line-height: 24px;
	margin-right: 16px;
	padding: 10px 20px;
	text-decoration: none;
}
.doteoW {
	box-sizing: border-box;
	margin-left: calc(0rem);
	margin-right: calc(0rem);
	display: flex;
	flex-flow: row wrap;
	-webkit-box-pack: start;
	-webkit-box-align: center;
	align-items: center;
}
.textopen-btn-mail {
	padding-left: 5px;
	color: black;
}
.leLuvu {
	box-sizing: border-box;
	display: flex;
	flex-flow: row wrap;
	-webkit-box-pack: start;
	-webkit-box-align: stretch;
	align-items: stretch;
}

@media screen and (max-width: 770px) {
	.container-btn-mail {
		margin-bottom: 10px;
	}
	.leLuvu {
		display: block !important;
	}
	.link-btn-mail {
		margin-right: 0px !important;
	}
}
</style>
